<template>
  <div>
    <div>
      <section
        class=""
        style="
          padding: 50px;
          background-image: linear-gradient(to right, #363440, #000c22);
        "
      >
        <div class="container">
          <div class="">
            <h5 style="color: white">Car Auction</h5>
            <p style="color: white">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </p>
            <p style="color: white">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s.
            </p>
          </div>
        </div>
      </section>
      <carlist></carlist>      
       <!-- <CarList>
      </CarList>  -->
    </div>
  </div>
</template>
<script>
//import CarList from "@/components/CarList";
 export default {
   name: 'Auction',
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
     
    }
  },
   mounted() {

    console.log(this.$store.state.auth.status.loggedIn); 
  },
  }
</script>
<style>
@media (min-width: 1400px) {
  .bidz-container-list {
    max-width: 1100px;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 991px) {
  .bidlistwidthfor768-991 {
    width: 33.33%;
  }
  .bidzNumberz,
  .detailsButtonz {
    display: block !important;
    margin-top: 7px !important;
  }
  .bidlistImagewrap {
    position: relative;
    height: 100%;
  }
  .bidlistImagewrap img {
    width: 100%;
    height: 60%;
    overflow: auto;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .bidBtn {
    width: 100%;
  }
  .hideon768-991 {
    display: none;
  }
  .bidzNumberz {
    background-color: #018f06;
    padding: 5px;
    color: white;
    border-radius: 5px;
    margin: 0px auto;
  }
  .detailsButtonz {
    background-color: gray;
    color: white;
    width: 100%;
    padding: 5px;
    border-radius: 5px;
  }
}

.bidzNumberz,
.detailsButtonz {
  display: none;
}
</style>
